<template>
  <div class="market">
    <base-header></base-header>
    <div class="market_table">
      <div class="market_table_title">
        <p>{{ time }}</p>
        <p>{{ week }}</p>
      </div>
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <div v-if="noData">暂无数据</div>
        <template>
          <van-list
            @load="onLoad"
            v-model="loading"
            :finished="finished"
            :offset="100"
          >
            <div class="table_box">
              <div
                class="table_item"
                v-for="(item, index) in list"
                :key="index"
              >
                <div class="fast_time">
                  <div class="circle">
                    <div class="big"></div>
                  </div>
                </div>
                <div class="fastRight">
                  <div class="time_box">
                    <span class="time">{{
                      item.publishTime.substring(11, 16)
                    }}</span>
                    <span class="source" v-if="item.whaleId == null">{{
                      item.source
                    }}</span>
                  </div>

                  <div class="fast_title">
                    <span>{{ item.title }}</span>
                  </div>
                  <div class="fast_description">{{ item.description }}</div>
                </div>
              </div>
            </div>
          </van-list>
        </template>
      </van-pull-refresh>
    </div>
    <base-footer v-if="flag"></base-footer>
  </div>
</template>

<script>
import { getnews } from '@/api/index';
import baseHeader from '../../components/base-header';
import baseFooter from '../../components/base-footer';
export default {
  name: '',
  components: {
    baseHeader,
    baseFooter
  },
  data() {
    return {
      flag: false,
      list: [],
      isLoading: false, // 下拉的加载图案
      loading: false, // 当loading为true时，转圈圈
      finished: false, // 数据是否请求结束，结束会先显示- 没有更多了 -
      noData: false, // 如果没有数据，显示暂无数据
      currentCurrency: '',
      pageNo: 1,
      pageSize: 20,
      week: '',
      time: ''
    };
  },
  mounted() {
    var now = new Date();
    var year = now.getFullYear(); //得到年份
    var month = now.getMonth() + 1; //得到月份
    var date = now.getDate(); //得到日期
    var day = now.getDay(); //得到周几
    var arr_week = new Array(
      '星期日',
      '星期一',
      '星期二',
      '星期三',
      '星期四',
      '星期五',
      '星期六'
    );
    var arr_weekE = new Array(
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    );
    var arr_weekK = new Array(
      '일요일',
      '월요일',
      '화요일',
      '수요일',
      '목요일',
      '금요일',
      '토요일'
    );
    var arr_weekJ = new Array(
      'にちようび',
      'げつようび',
      'かようび',
      'すいようび',
      'もくようび',
      'きんようび',
      'どようび'
    );
    // this.week = arr_week[day];
    if (this.$i18n.locale == 'ZH') {
      this.time = year + '年' + month + '月' + date + '日';
      this.week = arr_week[day];
    } else if (this.$i18n.locale == 'EN') {
      this.time = year + '/' + month + '/' + date;
      this.week = arr_weekE[day];
    } else if (this.$i18n.locale == 'KO') {
      this.time = year + '/' + month + '/' + date;
      this.week = arr_weekK[day];
    } else if (this.$i18n.locale == 'JA') {
      this.time = year + '/' + month + '/' + date;
      this.week = arr_weekJ[day];
    }
  },
  methods: {
    getmarketcurrencies() {
      getnews({
        language: this.$i18n.locale,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      })
        .then(res => {
          if (res.resultStatus) {
            this.isLoading = false;
            this.loading = false;
            this.pageNo++;
            for (let i = 0; i < res.resultData.length; i++) {
              this.list.push(res.resultData[i]);
            }
            if (this.list.length == 0 && this.pageNo === 1) {
              this.noData = true;
            }
            // 如果加载完毕，显示没有更多了
            if (res.resultData.length == 0) {
              this.finished = true;
              this.flag = true;
            }
          }
        })
        .catch(() => {});
    },
    onLoad() {
      this.loading = true;
      this.getmarketcurrencies();
    },
    onRefresh() {
      // 重新初始化这些属性
      this.isLoading = false;
      this.list = [];
      this.pageNo = 1;
      this.pageSize = 20;
      this.loading = false;
      this.finished = false;
      this.noData = false;
      this.flag = false;
      // 请求信息
      this.getmarketcurrencies();
    }
  }
};
</script>

<style lang="scss" scoped>
.market {
  .market_top {
    padding: 0 28px 0 32px;
    // width: 100%;
    height: 108px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .pic {
      width: 56px;
      height: 56px;
      img {
        width: 100%;
      }
    }
    .title {
      font-size: 38px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      color: #333333;
      line-height: 53px;
      padding-right: 30px;
    }
    .search {
      width: 76px;
      height: 48px;
      background: #edf5ff;
      border-radius: 24px;
      // margin-right: 20px;
      .iconfont {
        font-size: 24px;
        font-weight: bolder;
        color: #007aff;
        text-align: center;
        line-height: 48px;
        display: flex;
        justify-content: center;
      }
    }
    .flex_row {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .language {
      padding: 0 15px;
      height: 48px;
      line-height: 48px;
      background: #edf5ff;
      border-radius: 24px;

      p {
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #0079ff;
      }
    }
    .currency {
      padding: 0 10px;
      height: 48px;
      line-height: 48px;
      background: #edf5ff;
      border-radius: 24px;
      p {
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #0079ff;
      }
    }
    .nav {
      width: 28px;
      height: 24px;
      img {
        width: 100%;
      }
    }
  }
  .market_table {
    .market_table_title {
      background: #eff3f5;
      display: flex;
      p {
        font-size: 24px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #a4afb5;
        line-height: 64px;
        margin-left: 32px;
      }
    }
    .table_box {
      padding: 26px 32px;
      .table_item {
        padding: 15px 20px 15px 0;
        display: flex;
        .fastRight {
          flex: 1;
          .time_box {
            display: flex;
            justify-content: space-between;
            margin-bottom: 25px;
            .time {
              background: rgba($color: #007aff, $alpha: 0.08);
              border-radius: 21px;
              font-size: 24px;
              font-family: PingFangSC, PingFangSC-Regular;
              font-weight: 400;
              text-align: left;
              color: #007aff;
              line-height: 33px;
              padding: 2px 19px;
            }
            .source {
              font-size: 28px;
              font-family: PingFangSC, PingFangSC-Regular;
              font-weight: 400;
              text-align: left;
              color: #999999;
              line-height: 40px;
            }
          }
          .fast_title {
            font-size: 32px;
            font-family: PingFangSC, PingFangSC-Semibold;
            font-weight: 600;
            text-align: left;
            color: #333333;
            line-height: 45px;
            margin-bottom: 14px;
          }
          .fast_description {
            line-height: 40px;
            max-height: 155px;
            overflow: hidden;
            font-size: 28px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: justify;
            color: #333333;
            margin-bottom: 124px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
          }
        }
        .fast_time {
          border-right: 2px dashed #eee;
          margin-right: 32px;
          .circle {
            position: relative;
            .big {
              top: 0;
              left: -13px;
              position: absolute;
              width: 10px;
              height: 10px;
              background: #fff;
              border-radius: 50%;
              border: 10px solid #007aff;
            }
          }
        }
      }
    }
  }
}
</style>
